

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}


[data-theme='compact'] p.site-description-item-profile-p {
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}
[data-theme="dark"] .site-description-item-profile-p {
  color: rgba(255,255,255,0.85);
}
[data-theme="dark"] .site-description-item-profile-wrapper {
  color: rgba(255,255,255,0.65);
}

/* .ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
} */

.ant-input{
  border-radius: 5px !important;
}

.ant-input-number{
  border-radius: 5px !important;
}

.ant-picker{
  border-radius: 5px !important;
}

.ant-select-selector{
  border-radius: 5px !important;
}

.form-btn-big {
  width: 150px;
  margin: 1em;
  border-radius: 5px !important;
}

.tenant-detail-card p {
  margin-bottom: 0px;
}

.deposit-note-card p{
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.45);
}

.deposit-note-card .ant-tag{
  float: right;
}

.deposit-note-card {
  border-bottom: 1px solid rgb(240, 240, 240);
  padding-top: 8px;
  padding-bottom: 8px;
}

.deposit-note-card:last-child {
  border-bottom: 0px solid rgb(240, 240, 240);
}

.page-controls-top{
  display:flex;
  justify-content: flex-end;
  background-color:#002140;
  padding: 20px;
}


/* Tables */
.table-striped-rows :nth-child(2n) td{
  background-color: #f7f7f7;
}
.table-striped-rows thead{
  background-color: #f1f1f1;
}

sup {
  color: lightgray;
}